import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import {
  Section,
  SectionPageTitle,
  SectionTitle,
  SectionVerticalSubtitle,
  GradientWrapper,
  CustomScrollBar,
  BreadCrumb,
  MarkdownContentWrapper,
  MarkdownContent,
  MarkdownContentWrapperInner,
} from "../components/Section";
import PrimaryButton from "../components/Button/PrimaryButton";
import SecondaryButton from "../components/Button/SecondaryButton";
import { ArrowLineIcon, HomeIcon, ArrowRight } from "../components/Icons";
import Img from "gatsby-image";
import checkOrange from "../images/check-orange.svg";
import checkBlue from "../images/check-blue.svg";
import BreakpointDown from "../components/Media/BreakpointDown";

const BreadCrumbFloat = styled(BreadCrumb)`
  position: absolute;
  margin-top: 100px;
  left: 0;
  right: 0;
  z-index: 3;
  ${BreakpointDown.lg`
    margin-top:0px;
    position:relative;
  `}
`;

const BorderTopSection = styled(Section)`
  border-top: 1px solid #cbd1e2;
  &:after {
    ${BreakpointDown.lg`
     background-size:cover;
     `}
    ${BreakpointDown.sm`
     opacity: 0.3;
     `}
  }
`;
const BannerSection = styled(Section)`
  background-blend-mode: multiply;
  &:after {
    width: 50%;
    ${BreakpointDown.lg`
     width:auto;
    `}
  }
  &:before {
    z-index: 1;
    background-size: cover;
  }
`;
const BannerWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 125px;
  padding-bottom: 75px;
  position: relative;
  z-index: 2;
  margin-left: -15px;
  margin-right: -15px;
  @media (max-width: 1366px) {
    padding-top: 100px;
    padding-bottom: 0px;
  }
  ${BreakpointDown.xl`
    padding-top:60px;
  `}
  ${BreakpointDown.lg`
   flex-direction:column;
   align-items:center;
   text-align:center;
  `}
   ${BreakpointDown.sm`
    padding-top:30px;
  `}
`;
const BannerWrapperItem = styled.div`
  width: 100%;
  max-width: 33.33%;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.lg`
   max-width:100%;
  `}
  h1 {
    font-size: 42px;
    ${BreakpointDown.md`
      font-size:34px;
     `}
  }
`;
const BecomeDealer = styled.div`
  position: relative;
`;
const OurProgressHeading = styled.div`
  font-size: 28px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 30px;
  ${BreakpointDown.lg`
   text-align:left;
   margin-bottom:15px;
 `}
`;
const BecomeDealerText = styled.div`
  font-size: 24px;
  line-height: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
  ${BreakpointDown.sm`
     line-height:32px;
     font-size:22px;
    `}
`;

const ImgWrapper = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;
  ${BreakpointDown.lg`
    margin-left:auto;
    margin-right:auto;
    margin-top:30px;
    margin-bottom:10px;
    max-width:300px;
  `}
`;

const OurProgress = styled.div`
  color: #fff;
  ${BreakpointDown.lg`
    color:inherit;
    text-align:left;
 `}
`;
const ListItem = styled.div`
  display: flex;
  margin-bottom: 30px;
  counter-reset: count;
  li {
    position: relative;
    padding-left: 55px;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      counter-increment: count;
      content: "0" counter(count);
      font-size: 34px;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      margin-right: 12px;
      line-height: 51px;
      ${BreakpointDown.lg`
      font-size:26px;
      line-height:36px;
  `}
    }
  }
  &:last-of-type {
    margin-bottom: 30px;
  }
  ${BreakpointDown.lg`
    margin-bottom:8px;
  `}
`;

// BenifitsCard
const BenifitsCard = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
`;
const BenifitsCardItem = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  flex: 1;
  ${BreakpointDown.lg`
      flex:1 1 50%;
      width:100%;
      margin-bottom:30px;
  `}
  ${BreakpointDown.sm`
      flex:1 1 100%;
      width:100%;
      margin-bottom:30px;
  `}
`;

// Eligibility Critiarea
const SectionVerticalSubtitleRight = styled(SectionVerticalSubtitle)`
  transform: translateY(-50%) rotate(90deg);
`;
const CriteriaWrapper = styled.div`
  display: flex;
  ul {
    li {
      &:before {
        background: url(${checkOrange});
      }
    }
  }
`;
const CriteriaWrapperItem = styled.div`
  width: 100%;
  max-width: 65%;
  ${BreakpointDown.lg`
  max-width:100%;
`}
`;
const BorderBox = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  &:before,
  &:after {
    content: "";
    flex: 1;
    height: 4px;
  }
  &:before {
    background: #223c7e;
  }
  &:after {
    background: #f36224;
  }
`;

const BecomeDealerRightBototm = styled.div`
  background: #f36224;
  border-radius: 5px;
  padding: 35px;
  width: 100%;
  max-width: 350px;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  position: absolute;
  bottom: -100px;
  right: 0;
  font-family: "Poppins", sans-serif;
  ${BreakpointDown.lg`
        position:inherit;
        bottom: -60px;
        margin-right:auto;
        margin-left:auto;

    `}
  ${BreakpointDown.sm`
       bottom: -40px;
    `}
`;
// const BecomeDealerRightTitle = styled.div`
//   font-size: 28px;
//   font-weight: 600;
// `;
const BecomeDealerRightText = styled.div`
  font-size: 22px;
  margin-bottom: 12px;
  margin-top: 10px;
  font-weight: 500;
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 50px #0000000f;
  border: 1px solid #cbd1e2;
  border-radius: 5px;
  position: relative;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  height: 100%;
  @media (max-width: 1440px) {
    padding: 20px;
  }
  ${BreakpointDown.xl`
    padding:15px;
  `}

  &:before {
    position: absolute;
    left: 5px;
    top: 5px;
    content: "";
    width: 0;
    height: 0;
    border-top: 20px solid #ebf0ff;
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #ebf0ff;
  }
`;
const Icon = styled.div`
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  svg {
    width: 100%;
  }
`;
const Title = styled.div`
  color: #121316;
  font-size: 19px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 5px;
  @media (max-width: 1440px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

function BecomeDealerPage(queryData) {
  let pageData = queryData.data.contentfulDealerProgramPage;
  return (
    <Layout pageName="dealer-form">
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription.metaDescription}
      />
      <BannerSection
        bgBefore={`url(${pageData.dealerFirstSection.backgroundImage.fluid.src})`}
        bgColor="#EBF0FF"
        bgAfter="#223C7E"
        pb="100px"
        pt="100px"
        xpb="60px"
        xpt="60px"
      >
        <BreadCrumbFloat top="75" xtop="15px">
          <div className="container">
            <Link to="/">
              <HomeIcon /> <ArrowRight />
              </Link>
              <Link className="line-after-heading">
              Become A Dealer
            </Link>
          </div>
        </BreadCrumbFloat>
        <div class="container">
          <BannerWrapper>
            <BannerWrapperItem>
              <BecomeDealer>
                <SectionPageTitle
                  textAlign="left"
                  lgTextAlign="center"
                  mb="10px"
                >
                  {pageData.dealerFirstSection.labelText}
                </SectionPageTitle>
                <BecomeDealerText>
                  {pageData.dealerFirstSection.subHeading}
                </BecomeDealerText>
                <Link to={pageData.dealerFirstSection.buttonUrl}>
                  <PrimaryButton
                    text={pageData.dealerFirstSection.buttonText}
                    icon={<ArrowLineIcon />}
                  />{" "}
                </Link>
              </BecomeDealer>
            </BannerWrapperItem>
            <BannerWrapperItem>
              <ImgWrapper>
                <Img
                  fluid={pageData.dealerFirstSection.sectionImage.fluid}
                  alt={pageData.dealerFirstSection.sectionImage.title}
                />
              </ImgWrapper>
            </BannerWrapperItem>
            <BannerWrapperItem>
              <OurProgress>
                <OurProgressHeading className="line-after-heading">
                  {pageData.dealerFirstSection.headingText}
                </OurProgressHeading>
                <ListItem
                  dangerouslySetInnerHTML={{
                    __html:
                      pageData.dealerFirstSection.contentText
                        .childMarkdownRemark.html,
                  }}
                />
                <Link to={pageData.dealerFirstSection.linkUrl}>
                  <SecondaryButton
                    text={pageData.dealerFirstSection.linkText}
                    icon={<ArrowLineIcon />}
                  />
                </Link>
              </OurProgress>
            </BannerWrapperItem>
          </BannerWrapper>
        </div>
      </BannerSection>
      <Section
        pt="100px"
        pb="100px"
        xpb="60px"
        xpt="60px"
        mpb="40px"
        mpt="40px"
      >
        <div class="container">
          <SectionVerticalSubtitleRight
            left="auto"
            right="0"
            ml="0"
            xxmr="-91px"
            mr="-200px"
          >
            {pageData.dealerBenefitsSection.labelText}
          </SectionVerticalSubtitleRight>
          <SectionTitle textAlign="left" mb="10px">
            {pageData.dealerBenefitsSection.headingText}
          </SectionTitle>
          <BenifitsCard>
            {pageData.dealerBenefitsSection.features.map((item) => {
              return (
                <BenifitsCardItem>
                  <Card>
                    <Icon>
                      <img src={item.image.file.url} alt={item.image.title} />
                    </Icon>
                    <Title>{item.titleText}</Title>
                  </Card>
                </BenifitsCardItem>
              );
            })}
          </BenifitsCard>
        </div>
      </Section>
      <GradientWrapper>
        <BorderTopSection
          pt="100px"
          pb="100px"
          xpt="60px"
          xpb="60px"
          mpb="40px"
          mpt="40px"
          bgColor="transparent"
          bgAfter={`url(${pageData.dealerCriteriaSection.backgroundImage.fluid.src})`}
        >
          <div className="container container-zindex-up">
            <SectionVerticalSubtitle
              left="0"
              right="auto"
              xxml="-80px"
              ml="-230px"
              mr="0"
            >
              {pageData.dealerCriteriaSection.labelText}
            </SectionVerticalSubtitle>
            <SectionTitle textAlign="left">
              {pageData.dealerCriteriaSection.headingText}
            </SectionTitle>
            <CriteriaWrapper>
              <CriteriaWrapperItem>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      pageData.dealerCriteriaSection.contentText
                        .childMarkdownRemark.html,
                  }}
                />
              </CriteriaWrapperItem>
            </CriteriaWrapper>
            <BecomeDealerRightBototm>
              <BecomeDealerRightText>
                {pageData.dealerCriteriaSection.subHeading}
              </BecomeDealerRightText>
              <Link to={pageData.dealerCriteriaSection.buttonUrl}>
                <PrimaryButton
                  text={pageData.dealerCriteriaSection.buttonText}
                  icon={<ArrowLineIcon />}
                />
              </Link>
            </BecomeDealerRightBototm>
          </div>
          <BorderBox />
        </BorderTopSection>
      </GradientWrapper>
      <Section
        pt="100px"
        pb="80px"
        xpb="60px"
        xpt="60px"
        mpb="40px"
        mpt="40px"
        bgColor="transparent"
        bgBefore={`url(${pageData.dealerTermsSection.backgroundImage.fluid.src})`}
      >
        <div className="container">
          <SectionVerticalSubtitleRight
            left="auto"
            right="0"
            ml="0"
            xxmr="-87px"
            mr="-350px"
          >
            {pageData.dealerTermsSection.labelText}
          </SectionVerticalSubtitleRight>
          <CustomScrollBar maxHeight="650px">
            <MarkdownContent bg={checkOrange}>
              <MarkdownContentWrapper>
                <MarkdownContentWrapperInner>
                  <SectionTitle textAlign="left">
                    {pageData.dealerTermsSection.headingText}
                  </SectionTitle>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        pageData.dealerTermsSection.contentText
                          .childMarkdownRemark.html,
                    }}
                  />
                </MarkdownContentWrapperInner>
              </MarkdownContentWrapper>
            </MarkdownContent>
          </CustomScrollBar>
        </div>
      </Section>
      <GradientWrapper>
        <BorderTopSection
          pt="100px"
          pb="160px"
          xpb="70px"
          xpt="60px"
          mpt="40px"
        >
          <div className="container">
            <MarkdownContent bg={checkBlue}>
              <MarkdownContentWrapper>
                <MarkdownContentWrapperInner>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.contentSection.childMarkdownRemark.html,
                    }}
                  />
                </MarkdownContentWrapperInner>
              </MarkdownContentWrapper>
            </MarkdownContent>
          </div>
        </BorderTopSection>
      </GradientWrapper>
    </Layout>
  );
}

export default BecomeDealerPage;

export const query = graphql`
  query DealerProgramPage {
    contentfulDealerProgramPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      dealerFirstSection {
        labelText
        subHeading
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        backgroundImage {
          title
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            aspectRatio
          }
        }
        sectionImage {
          title
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            aspectRatio
          }
        }
        buttonText
        buttonUrl
        linkText
        linkUrl
      }
      dealerBenefitsSection {
        labelText
        headingText
        features {
          titleText
          image {
            title
            file {
              url
            }
          }
        }
      }
      dealerCriteriaSection {
        labelText
        subHeading
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        backgroundImage {
          fluid {
            src
          }
        }
        buttonText
        buttonUrl
      }
      dealerTermsSection {
        labelText
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        backgroundImage {
          fluid {
            src
          }
        }
      }
      contentSection {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
